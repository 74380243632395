import React, { useEffect, useState } from 'react';
import styles from './Picker.module.scss'
import { fabric } from 'fabric';
import cn from 'classnames';

interface Props {
  fabricCanvas: fabric.Canvas;
}

const tips = [
  {top: 1114.5169147682893, left: -88.02687098500246, scaleX: 0.8026370329955367, scaleY: 0.5452079925249876, angle: -67.4673652480248},
  {top: 146.23418904319124, left: 939.7548816831802, scaleX: 0.7123747945161089, scaleY: 0.48389547912336117, angle: 333.0364563665126},
  {top: -39.12416086108155, left: 1918.009698470738, scaleX: 0.7701955843414664, scaleY: 0.5231714599851459, angle: 359.68397044135435},
  {top: 489.9814993313156, left: 2800.588777132896, scaleX: 0.671398777101754, scaleY: 0.4560616622450493, angle: 30.03125051890931},
  {top: 1502.4765056203637, left: 3346.1456868216583, scaleX: 0.571790031438207, scaleY: 0.3884003383481533, angle: 86.47985431147853},
];

const Picker = ({ fabricCanvas }: Props) => {
  const [activeNail, setActiveNail] = useState('1');

  useEffect(() => {
    rerender(activeNail)
  }, [activeNail])

  const rerender = async (nail: string) => {
    const bgImage = await getImage('/hand/diffuse.jpg');
    const maskImage = await getImage('/hand/mask.png');

    bgImage.set({ top: 0, left: 0, scaleX: 1, scaleY: 1 });
    maskImage.set({ top: 0, left: 0, scaleX: 1, scaleY: 1 });

    const nails = await Promise.all(
      tips.map(async (tip) => {
        const nailImage = await getImage(`/hand/nails/${nail}.png`);
        nailImage.set(tip)
        return nailImage;
      })
    );

    fabricCanvas.remove(...fabricCanvas.getObjects());
    fabricCanvas.add(bgImage)
    nails.forEach(nail => fabricCanvas.add(nail))
    fabricCanvas.add(maskImage)
    fabricCanvas.renderAll()
  };

  const getImage = async (url: string): Promise<fabric.Image> => {
    return new Promise(resolve => {
      fabric.Image.fromURL(url, resolve)
    })
  };

  const handleClickNailImage = (image: string) => () => {
    setActiveNail(image)
  };

  return (
    <>
      <div className={styles.Picker}>
        {['1', '2'].map(tip => (
          <img
            src={`/hand/nails/${tip}.png`}
            alt={`Finger Nail ${tip}`}
            className={cn(styles.Picker__image, { [styles['Picker__image--active']]: tip === activeNail })}
            onClick={handleClickNailImage(tip)}
          />
        ))}
      </div>
    </>
  );
};

export default Picker;
