import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import styles from './Scene.module.scss'
import { fabric } from 'fabric';

interface Props {
  canvas: HTMLCanvasElement;
  fabricCanvas: fabric.Canvas;
}

const Scene = ({ canvas, fabricCanvas }: Props) => {
  const renderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = renderRef.current;

    if (!container) {
      return;
    }

    // RENDERER
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(container.clientWidth, container.clientHeight);
    // renderer.outputEncoding = THREE.sRGBEncoding;

    container.appendChild(renderer.domElement);

    // SCENE
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xcdcdcd);

    // CAMERA
    const camera = new THREE.PerspectiveCamera(50, container.clientWidth / container.clientHeight, 1, 1000);
    camera.position.set(-2.25, 1.4, 0.5);

    // MOUSE CONTROLS
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.update();

    // LIGHTS
    const pointLight = new THREE.PointLight(0x404040, 5, 50, 2);
    pointLight.position.set(0, 0, 0);
    camera.add(pointLight);
    scene.add(camera);

    const ambientLight = new THREE.AmbientLight(0x404040);
    scene.add(ambientLight);

    const normalTexture = new THREE.TextureLoader().load('/hand/normal.jpg')
    const specularTexture = new THREE.TextureLoader().load('/hand/specular.jpg')
    // const canvasTexture = new THREE.TextureLoader().load('/hand/diffuse.jpg')
    const canvasTexture = new THREE.CanvasTexture(canvas);
    // OBJECT
    const material = new THREE.MeshPhongMaterial({
      map: canvasTexture,
      normalMap: normalTexture,
      specularMap: specularTexture,
    });

    const objLoader = new OBJLoader();

    objLoader.load(
      "/hand/object.obj",
      (object) => {
        object.traverse((child: any) => {
          if (child.isMesh) {
            child.material = material;
          }
        });

        object.scale.set(10, 10, 10)

        scene.add(object);
      },
      // (progress) => console.log({ progress }),
      // (err) => console.log({ err }),
    );

    fabricCanvas.on('after:render', () => {
      if (material.map) {
        material.map.needsUpdate = true;
      }
    });

    // ANIMATE
    function animate() {
      requestAnimationFrame(animate);

      console.log(camera.position);
      renderer.render(scene, camera);
    }

    animate();
  }, [renderRef])

  return (
    <div ref={renderRef} className={styles.Scene} />
  );
};

export default Scene;
