import React from 'react';
import styles from './Container.module.scss'

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const Container = ({ children }: Props) => {
  return (
    <div className={styles.Container}>
      {children}
    </div>
  );
};

export default Container;
