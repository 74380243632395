import React, { useEffect, useState } from 'react';
import Scene from './components/Scene/Scene';
import Container from './components/Container/Container';
import Picker from './components/Picker/Picker';
import { fabric } from 'fabric';

function App() {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [fabricCanvas, setFabricCanvas] = useState<fabric.Canvas>()

  useEffect(() => {
    setFabricCanvas(
      new fabric.Canvas(canvas, {
        backgroundColor: '#ffdbac',
      })
    );
  }, [canvas])

  return (
    <Container>
      <canvas ref={setCanvas} width={4096} height={4096} />
      {canvas && fabricCanvas && (
        <>
          <Scene canvas={canvas} fabricCanvas={fabricCanvas} />
          <Picker fabricCanvas={fabricCanvas} />
        </>
      )}
    </Container>
  );
}

export default App;
